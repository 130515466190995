<template>
    <div class="row my-3">
        <div class="col-12">
            <div class="form-group">
                 <label class="text-color">Name of bank </label>
                <input type="text" id="bank_name" name="bank_name" class="form-control"/>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-6">
             <label class="text-color">Bank branch </label>
            <input type="text" id="bank_branch" name="bank_branch" class="form-control"/>
        </div>
        <div class="col-6">
             <label class="text-color">IFSC code </label>
            <input type="text" id="ifsc_code" name="ifsc_code" class="form-control"/>
        </div>
    </div>
    <div class="row my-3">
        <div class="col-12">
            <div class="form-group">
                 <label class="text-color">Account holder name </label>
                <input type="text" id="account_name" name="account_name" class="form-control"/>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div class="col">
             <label class="text-color">Account number </label>
            <input type="text" id="account_number" name="account_number" class="form-control"/>
        </div>
        <div class="col">
             <label class="text-color">Account type </label>
            <input type="text" id="account_type" name="account_type" class="form-control"/>
        </div>
    </div>
</template>