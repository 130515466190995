<template>
    <div class="row">
        <div class="col-12">
            <label class="text-color">Posts</label>
        </div>
        <div class="col-12 form-group">
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="post1" id="post1" value="post1">
                <label class="form-check-label" for="post1">Publish my posts to all the doctor groups</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="post2" id="post2" value="post2">
                <label class="form-check-label" for="post2">Publish my posts to all patient groups that I am part of</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="post3" id="post3" value="post3">
                <label class="form-check-label" for="post3">Allow public who have not signed up on Rxlx to view my posts</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="post4" id="post4" value="post4">
                <label class="form-check-label" for="post4">Allow all Rxlx members to comment on my posts</label>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <label class="text-color">Messages and notifications</label>
        </div>
        <div class="col-12 form-group">
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mn1" id="mn1" value="mn1">
                <label class="form-check-label" for="mn1">Allow members to send me private messages</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mn2" id="mn2" value="mn2">
                <label class="form-check-label" for="mn2">Allow doctors to send me queries about cases</label>
            </div>
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mn3" id="mn3" value="mn3">
                <label class="form-check-label" for="mn3">Send notifications to my primary mail ID and mobile number</label>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <label class="text-color">Members' queries</label>
        </div>
        <div class="col-12 form-group">
            <div class="form-check form-check">
                <input class="form-check-input" type="checkbox" name="mq1" id="mq1" value="mq1">
                <label class="form-check-label" for="mq1">Allow members' healthcare queries to reach me</label>
            </div>
        </div>
    </div>
</template>